import React from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CardSetupForm from "./CardSetupForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CardFormContainer({ user, setUser, handleBack, amount }) {
  return (
    <Elements stripe={stripePromise}>
      <CardSetupForm
        amount={amount}
        user={user}
        setUser={setUser}
        handleBack={handleBack}
      />
    </Elements>
  );
}

export default CardFormContainer;
