import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import { Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Users({ users }) {
  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    debugger;
    if (!query) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers((users) =>
        users.filter(
          (user) =>
            user.email.includes(query) || user.phoneNumber.includes(query)
        )
      );
    }
  }, [query, users]);

  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Users</Title>
      <Grid item xs={12} sm={6} style={{ margin: "20px 0" }}>
        <TextField
          name="query"
          value={query}
          onChange={(e) => setQuery(e.target.value.trim().toLowerCase())}
          variant="outlined"
          required
          fullWidth
          autoComplete="off"
          label="Search by email or phone"
        />
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Messages sent</TableCell>

            {/* <TableCell>Address</TableCell> */}
            <TableCell>Next message date</TableCell>
            <TableCell align="right">Amount Paid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phoneNumber}</TableCell>
              <TableCell>{row.smsCount}</TableCell>
              <TableCell>
                {row.nextSmsDate
                  ? new Date(row.nextSmsDate).toDateString()
                  : "-"}
              </TableCell>
              <TableCell align="right">{row.amountPaid || "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div> */}
    </React.Fragment>
  );
}
