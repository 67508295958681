import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Toast = ({ color, text, number, onClose }) => {
  return (
    <Snackbar open={true} autoHideDuration={3000} onClose={onClose}>
      <Alert onClose={onClose} severity={color}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
