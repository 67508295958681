import { Button } from "@material-ui/core";
import { ArrowBackSharp } from "@material-ui/icons";
import {
  usePayPalScriptReducer,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import { useState } from "react";
import Toast from "../../../../components/Toast";

const PaypalPayment = ({ user, setUser, handleBack, amount }) => {
  const [{ isPending }] = usePayPalScriptReducer();

  const [feedback, setFeedback] = useState();

  const confirmPayment = async () => {
    console.log(user.email);
    try {
      const res = await fetch(
        "https://api.belerealty.com/api/stripe/confirm-payment",
        {
          method: "POST",
          body: JSON.stringify({
            email: user?.email,
            amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setFeedback({
        color: "success",
        text: "Successfully paid for the service",
      });
      const updatedUser = {
        ...user,
        hasPaid: true,
      };
      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));
    } catch (e) {
      setFeedback({
        color: "error",
        text: "Could not complete the payment. Please try again.",
      });
    }
  };

  function onApprove(data, actions) {
    return actions.order.capture().then(() => {
      confirmPayment();
    });
  }
  function onError(err) {
    console.log(err);
    setFeedback({ color: "error", text: "Unexpected error occured" });
  }

  function createOrder(data, actions) {
    return actions.order
      .create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              value: amount,
              currency_code: "USD",
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#fff",

        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
      }}
    >
      <Button
        variant="contained"
        color="default"
        onClick={handleBack}
        style={{ margin: "4px", width: "100px" }}
        startIcon={<ArrowBackSharp />}
      >
        Back
      </Button>
      {isPending ? (
        <p>Loading</p>
      ) : (
        <PayPalButtons
          fundingSource={FUNDING.PAYPAL}
          style={{
            color: "silver",
            shape: "rect",
            label: "pay",
            height: 40,
            layout: "horizontal",
          }}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      )}
      {feedback?.text && (
        <Toast
          color={feedback.color}
          onClose={() => setFeedback(null)}
          text={feedback.text}
          number={1}
        />
      )}
    </div>
  );
};

export default PaypalPayment;
