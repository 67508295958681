import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import "./index.scss";
import Paypallogo from "../../../assets/paypal.png";
import cryptologo from "../../../assets/crypto.png";
import creditcardlogo from "../../../assets/credit-card.png";
import { minAmount } from "../../../utility";

class PaymentOptionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "credit-card",
      initial: "",
      checked: false,
      selectedOption: "credit-card",
      text: "Custom amount",
      prepend: "",
      empty: true,
      amount: minAmount,
    };
  }

  handleChange(e) {
    this.setState({
      selectedOption: e.target.value,
      value: e.target.value,
    });
  }

  handleFocus(e) {
    e.target.select();
    this.setState({
      value: "$" + e.target.value,
    });
  }

  handleText(e) {
    this.setState({
      initial: e.target.value,
      value: e.target.value,
    });
    if (!e.target.value == "") {
      this.setState({
        empty: false,
      });
    } else {
      this.setState({
        empty: true,
      });
    }
  }

  changeText(e) {
    this.setState({
      selectedOption: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.target.blur();
      document.getElementById("custom_radio").focus();
    }
  };

  render() {
    const { last, min, bal, custom } = { ...this.props };
    return (
      <div className="pen">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8} style={{ marginBottom: "10px" }}>
            <TextField
              error={
                !this.state.amount ||
                Number.parseInt(this.state.amount) < minAmount
              }
              name="amount"
              value={this.state.amount}
              onChange={(e) => {
                this.setState({ ...this.state, amount: e.target.value });
              }}
              variant="outlined"
              fullWidth
              autoComplete="off"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    Min. ${minAmount}
                  </InputAdornment>
                ),
                inputProps: {
                  min: minAmount,
                },
              }}
              min="5"
              label="Please pay us what you feel we deserve"
            />
          </Grid>
        </Grid>

        <form onSubmit={this.onSubmit}>
          <div className="form__content">
            <label
              className={
                this.state.selectedOption === "credit-card"
                  ? "selected form-row"
                  : "not-selected form-row"
              }
              htmlFor="credit-card"
            >
              <div className="row">
                <div
                  className={
                    this.state.selectedOption === "credit-card"
                      ? "data-cluster-checked"
                      : "data-cluster"
                  }
                >
                  <div className="input-text amount">{last}</div>
                  <div className="subLabel">
                    <div>Pay with Credit Card</div>
                    <img src={creditcardlogo} className="payment-option-logo" />
                  </div>
                </div>
              </div>
              <input
                name="credit-card"
                type="radio"
                className="custom-radio-btn"
                onChange={(e) => this.handleChange(e)}
                checked={this.state.selectedOption === "credit-card"}
                value="credit-card"
              />
            </label>
            <label
              className={
                this.state.selectedOption === "paypal"
                  ? "selected form-row"
                  : "not-selected form-row"
              }
              htmlFor="paypal"
            >
              <div className="row">
                <div
                  className={
                    this.state.selectedOption === "paypal"
                      ? "data-cluster-checked"
                      : "data-cluster"
                  }
                >
                  <div className="input-text amount">{min}</div>
                  <div className="subLabel">
                    <div>Pay with Paypal</div>

                    <img className="payment-option-logo" src={Paypallogo} />
                  </div>
                </div>
              </div>

              <input
                name="paypal"
                className="custom-radio-btn"
                type="radio"
                onChange={(e) => this.handleChange(e)}
                checked={this.state.selectedOption === "paypal"}
                value="paypal"
              />
            </label>
            {/* <label
              style={{ opacity: 0.4, cursor: 'unset' }}
              className={
                this.state.selectedOption === 'crypto'
                  ? 'selected form-row'
                  : 'not-selected form-row'
              }
              htmlFor='crypto'
            >
              <div className='row'>
                <div
                  className={
                    this.state.selectedOption === 'crypto'
                      ? 'data-cluster-checked'
                      : 'data-cluster'
                  }
                >
                  <div className='input-text amount'>{bal}</div>
                  <div className='subLabel'>
                    <div>Pay with Cryptocurrency ( Coming soon )</div>

                    <img src={cryptologo} className='payment-option-logo' />
                  </div>
                </div>
              </div>
              <input
                type='radio'
                name='crypto'
                className='custom-radio-btn'
                onChange={(e) => this.handleChange(e)}
                checked={this.state.selectedOption === 'crypto'}
                disabled={true}
                value='crypto'
              />
            </label> */}
          </div>
          <Button
            onClick={() =>
              this.props.onClick(this.state.value, this.state.amount)
            }
            fullWidth
            disabled={
              !this.state.amount ||
              Number.parseInt(this.state.amount) < minAmount
            }
            variant="contained"
            className="proceed-to-pay-btn"
            color="primary"
          >
            Proceed to pay
          </Button>
        </form>
      </div>
    );
  }
}

export default PaymentOptionSelector;
