import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { Button, LinearProgress, makeStyles, Paper } from "@material-ui/core";
// import apiClient from '../../apiClient';
// import { setupPayment } from '../../apiClient/requests';
// import { loginSelector } from '../../store/reducers/auth/login';
// import { useDispatch, useSelector } from 'react-redux';
// import Toast from '../../components/Toast';
// import { confirmPayment } from '../../store/actions/payment/payment';
// import { Wrapper } from '../auth/ResetPassword';
import StripeLogo from "../../../../assets/stripe-logo.svg";
import Toast from "../../../../components/Toast";
import { ArrowBackSharp, Feedback } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#F12525",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F12525",
    },
    "&:focused": {
      backgroundColor: "#F12525",
    },
  },
}));

export default function CardSetupForm({ user, setUser, handleBack, amount }) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  // const dispatch = useDispatch();
  // const authState = useSelector((state) => loginSelector(state));

  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  const fetchClientSecret = async () => {
    try {
      const res = await fetch(
        "https://api.belerealty.com/api/stripe/create-payment-intent",
        {
          method: "POST",
          body: JSON.stringify({
            amount: amount,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resJson = await res.json();
      setClientSecret(resJson.clientSecret);
    } catch (e) {}
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    setLoading(true);
    event.preventDefault();

    setFeedback();

    if (!stripe || !elements || !clientSecret) {
      setLoading(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: user?.firstName + " " + user?.lastName,
          email: user?.email,
        },
      },
    });

    if (result.error) {
      // error message toast
      setFeedback({ text: result.error.message, color: "error" });
      setLoading(false);
      return;
      // Display result.error.message in your UI.
    } else {
      setLoading(false);
      setFeedback({
        text: "Successfully paid for the service.",
        color: "success",
      });
      const updatedUser = {
        ...user,
        hasPaid: true,
      };
      setUser(updatedUser);
      localStorage.setItem("user", JSON.stringify(updatedUser));
    }
  };

  return (
    <div className="position-relative">
      {loading ? (
        <div className="loading-overlay">
          {/* <LinearProgress color="primary" /> */}
        </div>
      ) : null}
      {/* {error ?
      <Toast color="error" text={error} />
      : null} */}

      <div
        style={{
          padding: "20px",
          backgroundColor: "#fff",

          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
        className="payment-form-wrapper"
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ margin: "4px", width: "100px" }}
          startIcon={<ArrowBackSharp />}
        >
          Back
        </Button>
        <form onSubmit={handleSubmit}>
          <CardSection amount={amount} />
          <br />
          <div style={{ textAlign: "center" }}>
            <img src={StripeLogo} style={{ height: "100px" }} />
          </div>
          <Button
            fullWidth
            type="submit"
            disabled={loading}
            variant="contained"
            className={classes.submit}
            color="primary"
            mb={2}
          >
            Submit
          </Button>
        </form>
      </div>
      {feedback?.text && (
        <Toast
          color={feedback.color}
          onClose={() => setFeedback(null)}
          text={feedback.text}
          number={1}
        />
      )}
    </div>
  );
}
