import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PersonAdd from "@material-ui/icons/PersonAdd";
import CreditCardTwoTone from "@material-ui/icons/CreditCardTwoTone";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SignUp from "./Register";
import CardFormContainer from "./PaymentForm/stripe/CardFormContainer";
import PaymentOptionSelector from "./PaymentOptionSelector";
import { MoneyTwoTone } from "@material-ui/icons";
import PaypalPayment from "./PaymentForm/paypal";
import { AppBar, Toolbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PersonAdd />,
    2: <CreditCardTwoTone />,
    3: <MoneyTwoTone />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
  },
  stepper: {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    padding: "40px 20px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Register for an account", "Choose payment method", "Proceed to pay"];
}

function getStepContent(
  step,
  handleNext,
  handleBack,
  user,
  setUser,
  paymentOptionSelected,
  setPaymentOptionSelected,
  amount,
  setAmount
) {
  switch (step) {
    case 0:
      return <SignUp user={user} setUser={setUser} onClick={handleNext} />;
    case 1:
      return (
        <PaymentOptionSelector
          onClick={(selectedOption, amount) => {
            setPaymentOptionSelected(selectedOption);
            setAmount(amount);
            handleNext();
          }}
        />
      );
    case 2:
      return (
        <>
          {user?.hasPaid ? (
            <div
              style={{
                padding: "50px",
                backgroundColor: "#fff",

                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
              }}
            >
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Thanks for doing business with us —{" "}
                <strong>you have successfully paid for the service!</strong>
              </Alert>
            </div>
          ) : paymentOptionSelected === "credit-card" ? (
            <CardFormContainer
              user={user}
              setUser={setUser}
              handleBack={handleBack}
              onClick={handleNext}
              amount={amount}
            />
          ) : paymentOptionSelected === "paypal" ? (
            <PaypalPayment
              user={user}
              setUser={setUser}
              handleBack={handleBack}
              amount={amount}
            />
          ) : (
            <div>Coming Soon</div>
          )}
        </>
      );
    default:
      return "Unknown step";
  }
}

export default function CustomizedSteppers({ user, setUser }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [paymentOptionSelected, setPaymentOptionSelected] = React.useState(
    "credit-card"
  );

  const [amount, setAmount] = useState(5);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    setActiveStep(0);
  };

  useEffect(() => {
    if (user?.hasPaid) {
      setActiveStep(2);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <Stepper
        className={classes.stepper}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <>
            {user?.token ? (
              <AppBar variant="elevation" size position="static">
                <Toolbar variant="dense">
                  <div
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p>
                      Welcome {user?.firstName} {user?.lastName}
                    </p>
                    <Button
                      style={{ backgroundColor: "whitesmoke", width: "100px" }}
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                </Toolbar>
              </AppBar>
            ) : null}
            {getStepContent(
              activeStep,
              handleNext,
              handleBack,
              user,
              setUser,
              paymentOptionSelected,
              setPaymentOptionSelected,
              amount,
              setAmount
            )}
          </>
        )}
      </div>
    </div>
  );
}
