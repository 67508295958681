import React, { useEffect, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import data from "../../../data";
import { checkValidity } from "../../../utility";
import Toast from "../../../components/Toast";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px 60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#F12525",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F12525",
    },
    "&:focused": {
      backgroundColor: "#F12525",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SignUp(props) {
  const { user, setUser } = props;

  const classes = useStyles();
  const [view, setView] = useState("signup");
  const [state, setState] = useState({
    country: {
      value: "US",
      valid: true,
      rules: {
        isRequired: true,
      },
    },
    email: {
      value: "",
      valid: true,
      rules: {
        isEmail: true,
        isRequired: true,
      },
    },
    firstName: {
      value: "",
      valid: true,
      rules: {
        isRequired: true,
      },
    },
    lastName: {
      value: "",
      valid: true,
      rules: {
        isRequired: true,
      },
    },
    region: {
      value: "",
      valid: true,
      rules: {
        isRequired: true,
      },
    },
    password: {
      valid: true,
      value: "",
      rules: {
        minLength: 8,
        maxLength: 16,
        isRequired: true,
      },
    },
    confirmPassword: {
      valid: true,
      value: "",
      rules: {
        minLength: 8,
        maxLength: 16,
        isRequired: true,
      },
    },
  });

  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [feedback, setFeedback] = useState();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState("");

  const handleChange = (e, formKey) => {
    setState({
      ...state,
      [formKey]: {
        ...state[formKey],
        value: e.target.value,
        valid: true,
      },
    });
  };

  const countryOptions = useMemo(
    () =>
      data.map((d) => ({
        value: d.countryShortCode,
        label: d.countryName,
      })),
    []
  );

  const regionOptions = useMemo(() => {
    return state.country.value
      ? data
          .find((d) => d.countryShortCode === state.country.value)
          .regions.map((reg) => ({
            value: reg.shortCode,
            label: reg.name,
          }))
      : [];
  }, [state.country.value]);

  const login = async () => {
    setLoading(true);
    try {
      const res = await fetch("https://api.belerealty.com/api/auth/signin", {
        body: JSON.stringify({
          email: state.email.value,
          password: state.password.value,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resJSON = await res.json();
      if (resJSON.success === true) {
        const u = { ...resJSON.user, token: resJSON.token };
        localStorage.setItem("user", JSON.stringify(u));
        setUser(u);
      } else {
        const msg = resJSON?.msg;
        setFeedback({ color: "error", text: msg });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (user && !user.isAdmin) {
      props.onClick();
    } else if (user?.isAdmin) {
      setRedirect("/admin");
    }
  }, [user]);

  const handleLogin = (e) => {
    e.preventDefault();
    const emailValid = checkValidity(state.email.value, state.email.rules);
    const passwordValid = checkValidity(
      state.password.value,
      state.password.rules
    );
    setState({
      ...state,
      email: {
        ...state.email,
        valid: emailValid,
      },
      password: {
        ...state.password,
        valid: passwordValid,
      },
    });

    if (emailValid && passwordValid) {
      login();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = false;
    let shouldSubmit = true;
    const updatedState = state;
    Object.keys(state).forEach((formKey) => {
      if (formKey === "confirmPassword") {
        valid = checkValidity(
          state[formKey].value,
          state[formKey].rules,
          state.password.value
        );
      } else {
        valid = checkValidity(state[formKey].value, state[formKey].rules);
      }
      shouldSubmit = shouldSubmit && valid;
      updatedState[formKey].valid = valid;
    });
    setState({ ...updatedState });
    const isPhoneValid =
      phone &&
      checkValidity(phone, {
        isRequired: true,
      });
    shouldSubmit = shouldSubmit && isPhoneValid;
    setPhoneValid(isPhoneValid);

    if (!shouldSubmit) {
      return;
    }
    try {
      setLoading(true);
      const res = await fetch("https://api.belerealty.com/api/auth/signup", {
        body: JSON.stringify({
          email: state.email.value,
          phoneNumber: phone,
          firstName: state.firstName.value,
          lastName: state.lastName.value,
          country: state.country.value,
          state: state.region.value,
          password: state.password.value,
          username: state.email.value,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resJSON = await res.json();
      if (resJSON.success === true) {
        login();
      } else {
        const msg = resJSON?.msg;
        setFeedback({ color: "error", text: msg });
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.paper}>
      {/* <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar> */}
      {/* <Typography style={{ color: 'black' }} component='h1' variant='h5'>
        Sign up
      </Typography> */}
      <form
        onSubmit={(e) => {
          if (view === "signup") {
            handleSubmit(e);
          } else {
            handleLogin(e);
          }
        }}
        className={classes.form}
        noValidate
      >
        <Grid container spacing={2}>
          {view === "signup" && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!state.firstName.valid}
                  name="firstName"
                  value={state.firstName.value}
                  onChange={(e) => handleChange(e, "firstName")}
                  variant="outlined"
                  required
                  fullWidth
                  autoComplete="off"
                  label="First Name"
                />
                {!state.firstName.valid && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    First Name is required.
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  required
                  error={!state.lastName.valid}
                  value={state.lastName.value}
                  onChange={(e) => handleChange(e, "lastName")}
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                />{" "}
                {!state.lastName.valid && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Last Name is required.
                  </FormHelperText>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              error={!state.email.valid}
              value={state.email.value}
              onChange={(e) => handleChange(e, "email")}
              variant="outlined"
              fullWidth
              label="Email"
            />
            {!state.email.valid && (
              <FormHelperText style={{ color: "#f44336" }}>
                Email is required.
              </FormHelperText>
            )}
          </Grid>
          {view === "signup" && (
            <>
              <Grid
                container
                spacing={2}
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <Grid item xs={6}>
                  <FormControl
                    error={!state.country.valid}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="age-native-simple">Country</InputLabel>
                    <Select
                      native
                      label="Country"
                      error={!state.country.valid}
                      value={state.country.value}
                      onChange={(e) => handleChange(e, "country")}
                      inputProps={{
                        name: "country",
                        id: "age-native-simple",
                      }}
                    >
                      <option disabled value={""} key={""}>
                        {""}
                      </option>
                      {countryOptions.map((countryOpt) => (
                        <option value={countryOpt.value} key={countryOpt.value}>
                          {countryOpt.label}
                        </option>
                      ))}
                    </Select>
                    {!state.country.valid && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        Country is required.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    error={!state.region.valid}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="age-native-simple">State</InputLabel>
                    <Select
                      native
                      label="Select Region"
                      value={state.region.value}
                      onChange={(e) => handleChange(e, "region")}
                      inputProps={{
                        name: "region",
                        id: "age-native-simple",
                      }}
                    >
                      <option disabled value={""} key={""}>
                        {""}
                      </option>
                      {regionOptions.map((regOpt) => (
                        <option value={regOpt.shortCode} key={regOpt.value}>
                          {regOpt.label}
                        </option>
                      ))}
                    </Select>
                    {!state.region.valid && (
                      <FormHelperText style={{ color: "#f44336" }}>
                        State is required.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  placeholder="Enter phone number"
                  country={state.country.value}
                  defaultCountry={state.country.value}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                    setPhoneValid(true);
                  }}
                  withCountryCallingCode={true}
                  international={true}
                />
                {!phoneValid && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Phone number is required.
                  </FormHelperText>
                )}
              </Grid>
            </>
          )}
          {view === "signup" ? (
            <Grid
              container
              style={{ marginLeft: "0px", marginRight: "0px" }}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  error={!state.password.valid}
                  type="password"
                  autoComplete="new-password"
                  value={state.password.value}
                  onChange={(e) => handleChange(e, "password")}
                  variant="outlined"
                  fullWidth
                  label="Password"
                />
                {!state.password.valid && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Password must contain at least 8 characters.
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={!state.confirmPassword.valid}
                  autoComplete="off"
                  type="password"
                  value={state.confirmPassword.value}
                  onChange={(e) => handleChange(e, "confirmPassword")}
                  variant="outlined"
                  fullWidth
                  label="Confirm Password"
                />
                {!state.confirmPassword.valid && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    Both passwords must match.
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField
                error={!state.password.valid}
                type="password"
                autoComplete="new-password"
                value={state.password.value}
                onChange={(e) => handleChange(e, "password")}
                variant="outlined"
                fullWidth
                label="Password"
              />
              {!state.password.valid && (
                <FormHelperText style={{ color: "#f44336" }}>
                  Password must contain at least 8 characters.
                </FormHelperText>
              )}
            </Grid>
          )}
        </Grid>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          color="secondry"
          type="submit"
          onClick={(e) => {
            if (view === "signup") {
              handleSubmit(e);
            } else {
              handleLogin(e);
            }
          }}
          className={classes.submit}
        >
          {view === "signup" ? "Sign Up" : "Sign In"}
        </Button>
        {view === "signup" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            Already have an account?
            <Button
              size="small"
              style={{ width: "70px" }}
              onClick={() => setView("signin")}
            >
              Sign in
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Don't have an account?
            <Button
              size="small"
              style={{ width: "70px" }}
              onClick={() => setView("signup")}
            >
              Sign up
            </Button>
          </div>
        )}
        {/* <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
      </form>
      {feedback?.text ? (
        <Toast
          onClose={() => setFeedback()}
          color={feedback.color}
          text={feedback.text}
        />
      ) : null}
    </div>
  );
}
