import { useEffect, useState } from "react";
import "./App.css";
import CustomizedSteppers from "./Views/PaymentStepper";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Container, createMuiTheme, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./Views/dashboard/Dashboard";

const THEME = createMuiTheme({
  typography: {
    fontFamily: `'Montserrat', 'Sans-serif'`,
  },
});

function App() {
  const [user, setUser] = useState();
  const [redirect, setRedirect] = useState("");
  const [showStepper, setShowStepper] = useState(false);

  const checkServer = async () => {
    const res = await fetch("https://api.belerealty.com");
    console.log(res);
  };

  useEffect(() => {
    checkServer();
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
    if (_user?.isAdmin) {
      setRedirect("/admin");
    }
  }, []);

  return (
    <Router>
      {redirect && <Redirect to={redirect} />}
      <Switch>
        <Route exact path="/">
          <PayPalScriptProvider
            options={{
              "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY,
            }}
          >
            <div class="context">
              <div className="stepper-wrapper">
                <ThemeProvider theme={THEME}>
                  <CustomizedSteppers user={user} setUser={setUser} />
                </ThemeProvider>
              </div>
            </div>
          </PayPalScriptProvider>
        </Route>
        <Route exact path="/admin">
          <CssBaseline />
          <Dashboard user={user} setUser={setUser} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
