export const checkValidity = (value, rules, shouldMatchValue) => {
  let isValid = true;
  if (!rules) {
    return true;
  }
  if (rules.isRequired) {
    isValid = value.trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isNumeric) {
    var pattern = /^[0-9]+$/;
    isValid = pattern.test(value) && isValid;
  }
  if (shouldMatchValue) {
    isValid = value === shouldMatchValue && isValid;
  }

  return isValid;
};

export const minAmount = 10;
